.__account-register__0e4fc .section-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  h3 {
    font-weight: 300;
    font-size: 1.15rem;
  }
}
.__account-register__0e4fc #register-form, .__account-register__0e4fc .token-input {
  min-width:   340px;
  max-width:  340px;
  margin: 0 auto 0;
  display: flex;
  flex-direction: column;
}

.__account-register__0e4fc {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100vh;
}
.__account-register__0e4fc .btn.btn-primary  {
  margin: 50px 0 30px 0 ;
}
.__account-register__0e4fc .account-login-footer {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #666;
  a {
    color: #212529;
  }
}
.__account-register__0e4fc .forgot-password {
  text-align: center;

}
