@import "ember-bootstrap/bootstrap";

@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i");
@import "pod-styles";

$primary: #3ba2fa;

body {
  font-family: "Roboto", sans-serif;
  color: #404040;
  background-color: #f6f6f6;
  font-size: 1.25rem;
}
.modal-lg,
.modal-xl {
  max-width: 80vw;
}
img {
  width: 100%;
}
.table .thead-dark th,
.badge-primary {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  background-image: linear-gradient(#017dc7, #017dc7);
}

.flex-center {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.space-small {
  margin-top: 20px;
}

.space-middle {
  margin-top: 40px;
}

.space-large {
  margin-top: 60px;
}

.has-shadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-margin-top {
  margin-top: 0;
}

hr {
  margin: 0;
  border: 0;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 40px;
  margin-top: 40px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.no-hover:hover {
  outline: inherit;
  border: inherit;
  box-shadow: inherit;
}

/* TYPO */
h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-weight: 400;
}

h1,
.h1 {
  padding: 20px 0px;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 500;
}

a {
  color: inherit;
  text-decoration: underline;
  transition: all 0.2s ease-in-out;
}

a:hover {
  color: #017dc7;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.font-size-20 {
  font-size: 1.25rem;
}

.text-uppercase {
  text-transform: uppercase;
}

/* BUTTONS */
.btn {
  transition: all 0.3s ease-in-out;
}

.btn-gradient {
  text-transform: uppercase;
  color: #ffffff;
  background-image: linear-gradient(to top right, #017dc7, #3ba2fa);
  border-radius: 999px;
  font-weight: 700;
}

.btn-gradient:hover {
  background-image: linear-gradient(#017dc7, #017dc7);
}

button.has-shadow:hover {
  box-shadow: 0px 0px 0px;
}

.infobox {
  color: #ffffff;
  background-color: #017dc7;
  border-radius: 5px;
  padding: 0 5px;
  width: fit-content;
}

/* BOXES */
.box {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 30px;
}

/* FONT-WEIGHTS */
.font-weight-thin {
  font-weight: 200;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-regular {
  font-weight: 400;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-bold {
  font-weight: 700;
}

.font-weight-black {
  font-weight: 900;
}

/* HEADER */
.wrapper--header {
  background: #ffffff;
  border-bottom: 1px solid #dddddd;
}

.title--outer {
  position: relative;
}

.title--outer:after {
  position: absolute;
  content: "";
  display: block;
  width: 1px;
  background: #dddddd;
  right: 0;
  top: 10px;
  height: 30px;
}

.title--header {
  line-height: 50px;
}

.search--form {
  width: 100%;
}

.search--input {
  border: none;
  border-bottom: 1px solid #dddddd;
  width: 100%;
}

.search--input:focus {
  outline: none;
  border-bottom: 1px solid #017dc7;
}
.container-scroll {
  transition: max-width 0.5s linear, margin-right 0.5s linear;
  flex: 0 95%;
  max-width: 95%;
}
/* SIDEBAR */
.wrapper--sidebar {
  background: white;
  border-right: 1px solid #dddddd;
  max-width: 5%;
  transition: max-width 0.5s linear, margin-right 0.5s linear;

  .hide-on-closed,
  .hide-on-closed-flex {
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease-in;
  }
  &:hover {
    .hide-on-open {
      display: none;
    }
    .hide-on-closed {
      display: block;
      opacity: 1;
    }
    .hide-on-closed-flex {
      display: flex;
      opacity: 1;
    }
    & ~ .container-scroll {
      flex: 0 75%;
      max-width: 75%;
    }
    max-width: 25%;
  }
  //height: calc(100vh - 51px);
}

/* -- AVATAR */
.sidebar--profile {
  flex-wrap: wrap;
}

.welcome-wrapper {
  margin: 30px 0;
}

.wrapper--sidebar .name--wrapper p {
  margin-top: 10px;
  margin-bottom: 5px;
}

.outer--circle--wrapper {
  width: 33.33%;
  padding-top: 33.33%;
  position: relative;
}

.outer--circle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #dddddd;
  border-radius: 100%;
  overflow: hidden;
}

.circle--spacer {
  position: absolute;
  width: 90%;
  height: 90%;
  top: 5%;
  bottom: 5%;
  left: 5%;
  right: 5%;
  background-color: #ffffff;
  border-radius: 100%;
}

.inner-circle {
  position: absolute;
  width: 80%;
  height: 80%;
  top: 10%;
  bottom: 10%;
  left: 10%;
  right: 10%;
  background-color: #dddddd;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  i {
    font-size: 50px;
    margin: 0 auto 0;
  }
}

.progress--always {
  position: absolute;
  width: 50%;
  height: 100%;
  left: 50%;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #017dc7;
}

.progress--under50 {
  position: absolute;
  width: 50%;
  height: 200%;
  left: 0;
  top: -50%;
  bottom: 0;
  right: 50%;
  background-color: #dddddd;
  transform-origin: center right;
  transform: rotate(-180deg);
  /* ALWAYS INTO NEGATIVE */
}

.progress--over50 {
  position: absolute;
  width: 50%;
  height: 200%;
  left: 50%;
  top: -50%;
  bottom: 0;
  right: 0;
  background-color: #017dc7;
  transform-origin: center left;
  transform: rotate(0deg) /* ALWAYS INTO */;
  display: none;
}

/* -- NAVIGATION */
.main--nav {
  margin: 50px 0 0 0;
  width: 100%;
  z-index: 99;
}

.nav--ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.nav--a {
  line-height: 60px;
  min-height: 60px;
  display: block;
  position: relative;
  text-decoration: none;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 18px;
}

.nav--li {
  padding-left: 35px;
  position: relative;
  z-index: 1;
}

.nav--li i {
  position: absolute;
  left: 0;
  font-size: 22px;
  top: 18px;
  width: 25px;
  display: inline-flex;
  justify-content: center;
}

.nav--a.active:before {
  content: "";
  position: absolute;
  left: -15px;
  top: 0;
  width: 5px;
  height: 60px;
  background-color: #017dc7;
}

.nav--a.active:after {
  content: "";
  position: absolute;
  left: -10px;
  top: 0;
  width: calc(100% + 25px);
  height: 60px;
  background-image: linear-gradient(
    to right,
    rgba(1, 125, 199, 0.1),
    rgba(1, 125, 199, 0)
  );
  background-size: 100% 100%;
  z-index: 0;
}

.mobile--logout i,
.desktop--logout p {
  line-height: 50px;
}

.wrapper--sidebar.mobile {
  position: fixed;
}

.wrapper--sidebar.s-close {
  right: 100%;
}

@media (min-width: 1200px) {
  .hidden-xl {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.9px) {
  .hidden-lg {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991.9px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 576px) and (max-width: 767.9px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 0px) and (max-width: 575.9px) {
  .hidden-xs {
    display: none !important;
  }
}

.background-primary {
  background: rgb(1, 125, 199);
  background: linear-gradient(
    90deg,
    rgba(1, 125, 199, 1) 0%,
    rgba(59, 162, 250, 1) 100%
  );
}

.fullheight {
  height: 100vh;
}

.form-control {
  border: 2px solid #ced4da;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 29px;
  height: calc(2.55rem + 2px);

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: none;
  }
}

.btn.btn-primary {
  text-transform: uppercase;
  color: #ffffff;
  background-color: #3ba2fa;
  border-radius: 999px;
  font-weight: 700;
  border-color: transparent;

  &:hover {
    background-color: #017dc7;
  }
}
.btn.btn-secondary {
  text-transform: uppercase;
  color: #ffffff;
  background-color: #5a6268;
  border-radius: 999px;
  font-weight: 700;
  border-color: transparent;

  &:hover {
    background-color: #7b858c;
  }
}

.flex-center-content {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  padding: 20vh 0vh;
  height: 100vh;
}

.row-fix {
  margin-right: -15px;
  margin-left: -15px;
}

.box {
  margin-bottom: 15px;
}

.box-blue {
  color: white;
  position: relative;
  padding-top: 70px;

  &::before {
    content: "";
    position: absolute;
    background: url("/lock-solid@2x.png") no-repeat;
    background-size: contain;
    left: 50%;
    top: 20px;
    margin-left: -16.5px;
    z-index: 2;
    width: 33px;
    height: 27px;
  }
}

.box-blue,
.box-disabled {
  background: rgba(59, 162, 250, 0.5);
  background-image: linear-gradient(to top right, #017dc7, #3ba2fa);
}

.box-content {
  font-size: 0.85rem;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.new-table {
  width: 100%;
}

.liquid-child > .row {
  margin-right: 0px;
  margin-left: 0px;
}

.logo {
  height: 40px;
  width: auto;
}
.infobox-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 50px 0px;
  flex-wrap: wrap;
}

.infobox-rounded {
  border-radius: 31px;
  padding: 0px 8px;
  min-width: 28px;
  max-height: 28px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 0px 5px 0px;
  font-size: 16px;
  background-color: white;
  color: #017dc7;
  border: 2px solid #017dc7;
  margin-bottom: 10px;

  &.full {
    color: white;
    background-color: #017dc7 !important;
  }
}
.infobox-rounded.unfilled {
  border: 2px solid rgba(59, 162, 250, 0.2);
  background: rgba(59, 162, 250, 0.2);
}
.infobox-rounded.done {
  background-color: #017dc7;
  color: white;
}

.coupon {
  position: relative;
  min-height: 150px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  picture {
    position: absolute;
    position: absolute;
    max-width: 1000px;
    left: 50%;
    margin-left: -500px;
  }
}
.coupon-info {
  margin: 20px 0px;
  z-index: 222222222;
  position: relative;
}

.question-group {
  max-width: 70%;
  margin: 0 auto 50px;
}

.answers .active {
  color: $primary;
}

.question {
  padding: 20px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  &.is--open {
    border: 2px solid rgba(59, 162, 250, 0.2);
    background: rgba(59, 162, 250, 0.2);
  }
}
.was-opened .question {
  &:not(.is--open) {
    border: 2px solid rgba(59, 162, 250, 0.2);
    background: rgb(129, 129, 129);
  }
}
.container-scroll {
  height: calc(100vh - 50px);
  overflow: scroll;
}
.question-body {
  text-align: left;
}

.question-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .question-option {
    display: flex;
    padding: 0px 15px;
  }
}

.highlighted {
  color: #017dc7;
}
[data-ember-action]:hover,
label.form-check-label {
  cursor: pointer;
}

.form-check-input-question {
  left: 40px;
}

.list-group-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  background: transparent;
  border: none;
}

.list-group-item::before {
  content: "";
  position: absolute;
  display: block;
  height: 1px;
  width: 100%;
  max-width: 500px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0)
  );
  top: 0;
}

.list-group-item:first-child::before {
  content: none;
}

form-check-label {
  order: 1;
}

.form-check-input {
  position: relative;
  margin-bottom: 2px;
  margin-left: 20px;
  margin-top: unset;
  left: 0;
  order: 2;
  width: 20px;
  height: 20px;
}

.ember-text-field:focus {
  outline: none;
  opacity: 1;
}

@import "ember-power-select/themes/bootstrap";

@import "ember-power-select";
.pageContainer a {
  text-decoration: underline;
}

[data-ember-action],
.btn {
  cursor: pointer;
}

.clear {
  clear: both;
}
@media (max-width: 767.9px) {
  .logo {
    height: auto;
    width: 30%;
  }
  .sidebar--profile {
    min-height: 120px !important;
  }
  .avatar--wrapper,
  .name--wrapper {
    display: none !important;
  }

  .coupon {
    height: 70px;
    min-height: auto;
    picture {
      display: none;
    }
  }
  .question-group {
    max-width: 95%;
  }
  .container-scroll {
    flex: 0 100%;
    max-width: 100%;
  }
  .wrapper-main .starTableContainer td {
    font-size: 10px;
  }
  .intro-slogan .intro-slogan-image {
    max-width: 130px !important;
  }
  .berichte-table {
    font-size: 0.8rem;
  }
}
#offcanvas {
  height: calc(100vh - 50px);
  background: white;
  position: fixed;
  top: 50px;
  width: 100%;
  z-index: 2;
  padding: 0px 15px;

  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
  .close {
    margin: 10px;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.badge-size-lg {
  font-size: 20px;
}
.td-special {
  padding-right: 0px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.group-15, .group-16 {
  background: yellow;
}
label.form-check-label {
  text-align: left;
}