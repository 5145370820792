.__account-signupall__629b3 .section-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  h3 {
    font-weight: 300;
    font-size: 1.15rem;
  }
}
.__account-signupall__629b3 #signin-form, .__account-signupall__629b3 .token-input {
  min-width:   340px;
  max-width:  340px;
  margin: 0 auto 0;
  display: flex;
  flex-direction: column;
}

.__account-signupall__629b3 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100vh;
}
.__account-signupall__629b3 .btn.btn-primary, .__account-signupall__629b3 .btn.btn-secondary  {
  margin: 0px 0 30px 0 ;
}
.__account-signupall__629b3 .account-login-footer {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #666;
  a {
    color: #212529;
  }
}
.__account-signupall__629b3 .forgot-password {
  text-align: center;

}

.__account-signupall__629b3 .form-check-input {
  margin-left: -1.25rem;
}
.__account-signupall__629b3 .form-check {
  .form-check-label {
    font-size: 14px;
  }
}
.__account-signupall__629b3 .checker {
  .form-group {
    margin-bottom: 0rem;
  }
}