.__app-questionnaire-question__8c752 .group-title {
  color: #3ba2fa;
  margin-top: 20px;
  margin-left: -20px;
  text-decoration: underline;
}

.__app-questionnaire-question__8c752 .btn.btn-primary {
  font-size: 0.7rem;
    position: relative;
    left: 50px;
}