.__account-password__3ddf8 .section-header {
    text-align: center;
    display: flex;
    flex-direction: column;
    h3 {
      font-weight: 300;
      font-size: 1.15rem;
    }
  }
  .__account-password__3ddf8 #password-form, .__account-password__3ddf8 .token-input {
    min-width:   340px;
    max-width:  340px;
    margin: 0 auto 0;
    display: flex;
    flex-direction: column;
  }
  
  .__account-password__3ddf8 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100vh;
  }
  .__account-password__3ddf8 .btn.btn-primary  {
    margin: 50px 0 30px 0 ;
  }
  .__account-password__3ddf8 .account-login-footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #666;
    a {
      color: #212529;
    }
  }
  .__account-password__3ddf8 .forgot-password {
    text-align: center;
  
  }
  