.__account-login__2471e .section-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  h3 {
    font-weight: 300;
    font-size: 1.15rem;
  }
}
.__account-login__2471e .login-form, .__account-login__2471e .token-input, .__account-login__2471e .login-form-quick {
  min-width: 330px;
  max-width:  340px;
  margin: 0 auto 0;
  display: flex;
  flex-direction: column;
}

.__account-login__2471e {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100vh;
}
.__account-login__2471e .btn.btn-primary  {
  margin: 50px 0 30px 0 ;
}
.__account-login__2471e .account-login-footer {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #666;
  a {
    color: #212529;
  }
}
.__account-login__2471e .forgot-password {
  text-align: center;

}
