.__sidebar-nav__3d8af {
  position: fixed;
  top: 5%;
  right: 20px;
  text-align: left;
  padding: 20px;
  border: 1px solid #DDDDDD;
  border-radius: 10px;

  z-index: 1;

}

.__sidebar-nav__3d8af.show {
  right: auto !important;
  left: 0px !important;
  width: 98vw;
  background: white !important;
}


.__sidebar-nav__3d8af [href^="#lexikon"] {
  font-style: italic;
}

@media (max-width: 767.9px) {
  .__sidebar-nav__3d8af {
    position: fixed;
    top: 70px;
    right: 10px;
    text-align: left;
    padding: 0px 0px 0px 10px;
    border: none;
    border-radius: 10px;
    background: transparent;
    height: calc(100vh - 65px);

  }

  .__sidebar-nav__3d8af .scrollcontainer {
    padding-bottom: 50px;
    overflow: scroll;
    height: calc(100vh - 150px);
  }

  .__sidebar-nav__3d8af ul {
    display: none;
  }

  .__sidebar-nav__3d8af.show ul {
    display: block;
  }

  .__sidebar-nav__3d8af.show a {
    display: inline;
  }

  .__sidebar-nav__3d8af.show .navigation {
    display: block;
  }

  .__sidebar-nav__3d8af.show .fa {
    display: none;
  }

  .__sidebar-nav__3d8af a.active {
    font-weight: bold;
  }

}

@media (min-width: 767.9px) {
  .__sidebar-nav__3d8af:hover a {
    display: inline;
  }

  .__sidebar-nav__3d8af:hover .navigation {
    display: block;
  }

  .__sidebar-nav__3d8af:hover .fa {
    display: none;
  }

  .__sidebar-nav__3d8af a.active {
    font-weight: bold;
  }

}

.__sidebar-nav__3d8af a,
.__sidebar-nav__3d8af .navigation {
  display: none;
}


.__sidebar-nav__3d8af ul {
  list-style-type: decimal;
  background: white;

}

.__sidebar-nav__3d8af .nav-icon {
  display: block;
  width: auto;
  text-align: center;
  color: #ffffff;

  background-image: linear-gradient(to top right, #017DC7, #3BA2FA);
  border-radius: 999px;
  padding: 3px 10px;
  margin-bottom: 20px;
}

.__sidebar-nav__3d8af .clearboth {
  clear: both;
}
